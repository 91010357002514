import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import Header from '../../Main/Header';
import button from '../../image/main/pngegg.png';
import NumbersContact from '../components/numbers';
import 'firebase/storage';
import FileDownloadLink from '../../FileDownloadLink';

const Page2 = ({ fileDetailsArray }) => {
  const [objects2, setObjects2] = useState({ name1: '' });
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const db = getDatabase();
    const objects2Ref = ref(db, 'objects2');
    const unsubscribe = onValue(objects2Ref, (snapshot) => {
      const data = snapshot.val();
      setObjects2(data);
      setIsVisible(data.isVisible);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const objects2Ref = ref(db, 'objects2');

    const fetchData = (snapshot, setState) => {
      const data = snapshot.val();
      setState(data);
    };

    onValue(objects2Ref, (snapshot) => fetchData(snapshot, setObjects2));
  }, []);

  useEffect(() => {
    if (objects2.name1) {
      document.title = objects2.name1;
    }
  }, [objects2.name1]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };
  return (
<div>
  <Header />
  <div className="container-id" style={{top: "80px", position: "relative"}}>
    <div className="container-left">
    <p style={{fontWeight: "700", display: "flex", alignItems: "center", justifyContent: "center"}}>Продажа</p>
        <span className="discount" style={{ borderRadius: "5px", display: isVisible ? "block" : "none"}}></span>
          <pre className="discount-end" style={{ display: isVisible ? "block" : "none"}}>{objects2 && objects2.dateend1}</pre>
            <pre className="lastprice">{objects2 && objects2.lastprice1}</pre>
            <pre className="newprice" style={{ position: "relative", top: isVisible ? "20px" : "0px"}}>{objects2 && objects2.newprice1}</pre>    

        
        <pre style={{ borderRadius: "5px", marginTop: isVisible ? "50px" : "5px"}}>{objects2 && objects2.sale1}</pre> 
      <p style={{fontWeight: "700"}}>Описание</p>
      <hr style={{margin: "-10px 0 20px 0"}}/>
        <pre className="characterPRE">{objects2 && objects2.character1}</pre>
        </div>
        <div className="container-right">
          <p className="address">{objects2 && objects2.name1}</p>
          <div className="slider-container">
            {Array.from({ length: 3 }, (_, index) => (
              <img
                key={index}
                className="slider-image"
                src={objects2[`imageUrl${index + 1}`]}
                alt={`Image ${index + 1}`}
                style={{ display: currentImageIndex === index ? "block" : "none" }}
              />
            ))}
            <div className="slider-buttons">
            <button className="arrow-button prev" onClick={handlePrevSlide}>
              <img className="prev" src={button} alt=""/>
            </button>
            <button className="arrow-button next" onClick={handleNextSlide}>
            <img className="next" src={button} alt=""/>
            </button>
            </div>
          </div>
          <div>
         
          <span style={{ marginTop: "20px" }}>
            Коммерческое предложение в   <FileDownloadLink fileUrl="https://firebasestorage.googleapis.com/v0/b/catalog-e0fb9.appspot.com/o/files%2F%D0%9A%D0%BE%D0%BC%D0%BC%D0%B5%D1%80%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%9A%D0%9F%20%D0%9B%D0%BE%D0%B4%D0%B5%D0%B9%D0%BD%D0%BE%D0%B5%20%D0%9F%D0%BE%D0%BB%D0%B5.pdf" fileName="Коммерческое предложение КП Лодейное Поле.pdf" />
          </span>
      </div>
      <div className="sale-product-cart__map">
            <iframe src={objects2 && objects2.mapLink} frameBorder="0" allowFullScreen={true} width="100%" height="300px"></iframe>
          </div>
              <NumbersContact />
            </div>
        </div>
</div>
  );
};

export default Page2;

