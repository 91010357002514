import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from 'firebase/database';
import Header from "../../Main/Header";
import FileDownloadLink from '../../FileDownloadLink';

const Page3 = () => {

const [objects3, setobjects3] = useState({ name1: '' });
const [isVisible3, setisVisible3] = useState(true);
 
  useEffect(() => {
    const db = getDatabase();
    const objects3Ref = ref(db, "objects3");
    const unsubscribe = onValue(objects3Ref, (snapshot) => {
      const data = snapshot.val();
      setobjects3(data);
      setisVisible3(data.isVisible3);
    });

    return () => {
      unsubscribe();
    };
  }, []);



  useEffect(() => {
      const db = getDatabase();
      const objects3Ref = ref(db, "objects3");

      const fetchData = (snapshot, setState) => {
        const data = snapshot.val();
        setState(data);
      };

      onValue(objects3Ref, (snapshot) => fetchData(snapshot, setobjects3));
      
  }, []);

const [numbers, setnumbers] = useState({});
  useEffect(() => {
    const db = getDatabase();
    const numbersRef = ref(db, "numbers");
    onValue(numbersRef, (snapshot) => {
      const data = snapshot.val();
      setnumbers(data);
    });
  }, []);

  useEffect(() => {
    if (objects3.name1) {
      document.title = objects3.name1;
    }
  }, [objects3.name1]);
  
  
  return (
<div>
  <Header />
  <div className="container-id" style={{top: "80px", position: "relative"}}>
    <div className="container-left">
    <p style={{fontWeight: "700", display: "flex", alignItems: "center", justifyContent: "center"}}>Продажа</p>
    <span className="discount" style={{ borderRadius: "5px", display: isVisible3 ? "block" : "none"}}></span>
            <div className="lastprice">
              <p>{objects3 && objects3.lastprice1} </p>
            </div>
            <div className="newprice" style={{ fontSize: isVisible3 ? "15px" : "30px", position: "relative", top: isVisible3 ? "20px" : "-10px",}}>{objects3 && objects3.newprice1} <br /></div>
            <div>{objects3 && objects3.dateend1}</div>
    
        
        <pre style={{marginTop: "20px", marginLeft: "-3px"}}>{objects3 && objects3.sale1}</pre> 
      <p style={{fontWeight: "700"}}>Описание</p>
      <hr style={{margin: "-10px 0 20px 0"}}/>
        <pre className="characterPRE">{objects3 && objects3.character1}</pre>
        </div>
        <div className="container-right">
          <p>{objects3 && objects3.name1}</p>
            <img src={objects3[`imageUrl1`]} width={"230px"} height={"190px"} style={{ borderRadius: "5px"}} />
            <span style={{ marginTop: "20px" }}>
            Коммерческое предложение в   <FileDownloadLink fileUrl="https://firebasestorage.googleapis.com/v0/b/catalog-e0fb9.appspot.com/o/files%2F%D0%9A%D0%BE%D0%BC%D0%BC%D0%B5%D1%80%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%9C%D0%BE%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%BE%D0%B5%20%D1%88%D0%BE%D1%81%D1%81%D0%B5%2079.pdf?alt=media&token=8731f86a-4e76-4311-8722-4bab64a87da4.pdf" fileName="Коммерческое предложение Московское шоссе 79.pdf" />
          </span>
          <div className="sale-product-cart__map">
            <iframe src={objects3 && objects3.mapLink} frameBorder="0" allowFullScreen={true} width="100%" height="300px"></iframe>
          </div>
              <div className="numbers">
              <h3 style={{ margin: "0", fontSize: "15px", width: "440px", lineHeight: "20px"}} className="h3 h3__sale">За более подробной информацией обращайтесь к 
нашим менеджерам по телефону: <a href="">{numbers && numbers.name2}</a></h3>
                
              </div>
            </div>
        </div>
</div>
  );
};

export default Page3;

