import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from 'firebase/database';
import Header from "../../Main/Header";
import FileDownloadLink from '../../FileDownloadLink';

const Page5 = () => {

const [objects5, setobjects5] = useState({ name1: '' });
const [isVisible5, setisVisible5] = useState(true);
 
  useEffect(() => {
    const db = getDatabase();
    const objects5Ref = ref(db, "objects5");
    const unsubscribe = onValue(objects5Ref, (snapshot) => {
      const data = snapshot.val();
      setobjects5(data);
      setisVisible5(data.isVisible5);
    });

    return () => {
      unsubscribe();
    };
  }, []);



  useEffect(() => {
      const db = getDatabase();
      const objects5Ref = ref(db, "objects5");

      const fetchData = (snapshot, setState) => {
        const data = snapshot.val();
        setState(data);
      };

      onValue(objects5Ref, (snapshot) => fetchData(snapshot, setobjects5));
      
  }, []);

const [numbers, setnumbers] = useState({});
  useEffect(() => {
    const db = getDatabase();
    const numbersRef = ref(db, "numbers");
    onValue(numbersRef, (snapshot) => {
      const data = snapshot.val();
      setnumbers(data);
    });
  }, []);

  useEffect(() => {
    if (objects5.name1) {
      document.title = objects5.name1;
    }
  }, [objects5.name1]);
  
  
  return (
<div>
  <Header />
  <div className="container-id" style={{top: "80px", position: "relative"}}>
    <div className="container-left">
    <p style={{fontWeight: "700", display: "flex", alignItems: "center", justifyContent: "center"}}>Продажа</p>
    <span className="discount" style={{ borderRadius: "5px", display: isVisible5 ? "block" : "none"}}></span>
            <div className="lastprice">
              <p>{objects5 && objects5.lastprice1} </p>
            </div>
            <div className="newprice" style={{ fontSize: isVisible5 ? "15px" : "30px", position: "relative", top: isVisible5 ? "20px" : "-10px",}}>{objects5 && objects5.newprice1} <br /></div>
            <div>{objects5 && objects5.dateend1}</div>
    
        
        <pre style={{marginTop: "20px", marginLeft: "-3px"}}>{objects5 && objects5.sale1}</pre> 
      <p style={{fontWeight: "700"}}>Описание</p>
      <hr style={{margin: "-10px 0 20px 0"}}/>
        <pre className="characterPRE">{objects5 && objects5.character1}</pre>
        </div>
        <div className="container-right">
          <p>{objects5 && objects5.name1}</p>
            <img src={objects5[`imageUrl1`]} width={"230px"} height={"190px"} style={{ borderRadius: "5px"}} />
            <span style={{ marginTop: "20px" }}>
            Коммерческое предложение в   <FileDownloadLink fileUrl="https://firebasestorage.googleapis.com/v0/b/catalog-e0fb9.appspot.com/o/files%2F%D0%9A%D0%BE%D0%BC%D0%BC%D0%B5%D1%80%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%95%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%BD%D0%BE%D0%B2%D0%BA%D0%B0.pdf?alt=media&token=0a2d2eef-8221-4a70-81fa-918402fec8f1.pdf" fileName="Коммерческое предложение Екатериновка.pdf" />
          </span>
          <div className="sale-product-cart__map">
            <iframe src={objects5 && objects5.mapLink} frameBorder="0" allowFullScreen={true} width="100%" height="300px"></iframe>
          </div>
              <div className="numbers">
              <h3 style={{ margin: "0", fontSize: "15px", width: "440px", lineHeight: "20px"}} className="h3 h3__sale">За более подробной информацией обращайтесь к 
нашим менеджерам по телефону: <a href="">{numbers && numbers.name2}</a></h3>
                
              </div>
            </div>
        </div>
</div>
  );
};

export default Page5;
