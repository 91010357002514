import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, update, set } from 'firebase/database';
import { useHistory, useNavigate } from 'react-router-dom';
import firebase from "../../../firebase";
import 'firebase/compat/storage';
import  s from "../modules/Manager.module.css"
import discount from "../../../image/main/discount.jpg"
import sold from "../../../image/main/sold.png"


export const GeneralEdit2 = ({ onFileUpload }) => {
  const [objects2, setobjects2] = useState({});
  const [pdfUrl, setPdfUrl] = useState(null);
  const [newValues, setNewValues] = useState({
    isVisible: false,
    isSold: false,
    mapLink: '',
  });
  const history = useNavigate();
  const database = getDatabase();
  const objects2Ref = ref(database, "objects2");
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const updates = { ...newValues, mapLink: newValues.mapLink || "" };
    Object.keys(updates).forEach((key) => {
      if (updates[key] !== "") {
        update(objects2Ref, { [key]: updates[key] });
      }
    });
    console.log('Form Updates:', updates);
  };
  
  const handleInputChange = (e, key) => {
    if (key === 'mapLink') {
      const newValue = e.target.value;
      setNewValues((prevValues) => ({ ...prevValues, mapLink: newValue }));
    } else {
      const newValue = e.target.value;
      setNewValues((prevValues) => ({ ...prevValues, [key]: newValue }));
    }
  };



  const handleFileUpload = (event, index) => {
    const file = event.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`files/${file.name}`);

    fileRef.put(file).then(() => {
      console.log("File uploaded successfully!");
      fileRef.getDownloadURL().then((url) => {
        setPdfUrl(url);
        setNewValues((prevState) => {
          const updatedValues = { ...prevState };
          updatedValues[`fileUrl${index}`] = url;
          return updatedValues;
        });
      });
    });
  };


  useEffect(() => {
    const storedLoggedIn = localStorage.getItem('loggedIn');
    if (storedLoggedIn !== 'true') {
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    if (objects2.name1) {
      document.title = objects2.name1 + " - Редактор";
    }
  }, [objects2.name1]);

  useEffect(() => {
    const fetchData = () => {
      onValue(objects2Ref, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setobjects2(data);
          setNewValues((prevValues) => ({
            ...prevValues,
            isVisible: data.isVisible || false,
            isSold: data.isSold || false,
          }));
          setDataLoaded(true); // Set dataLoaded to true when data is loaded
        }
      });
    };
  
    const delay = 100;
    const timeoutId = setTimeout(fetchData, delay);
  
    // Clear the timeout on component unmount or when objects2Ref changes
    return () => clearTimeout(timeoutId);
  
  }, [objects2Ref]);
  

  useEffect(() => {
    localStorage.setItem('generalEdit2FormValues', JSON.stringify(newValues));
    console.log('Form Values Saved:', newValues);
  }, [newValues]);

  if (!dataLoaded) {
    // Display a loading indicator or return null while data is being loaded
    return null;
  }


  const handleImageCheckboxChange = () => {
    setTimeout(() => {
      const updates = { ...objects2, isVisible: !newValues.isVisible };
      set(objects2Ref, updates);
    }, 100); // 999 milliseconds delay, adjust as needed
  };
  

  const handleSoldCheckboxChange = () => {
    setTimeout(() => {
      const updates = { ...objects2, isSold: !newValues.isSold };
      set(objects2Ref, updates);
    }, 100); 
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`images/${file.name}`);
    imageRef.put(file).then(() => {
      console.log("Изображение успешно загружено!");
      imageRef.getDownloadURL().then((url) => {
        setNewValues(prevState => {
          const updatedValues = { ...prevState };
          updatedValues[`imageUrl${index}`] = url;
          return updatedValues;
        });
      });
    });
  };


  


  return (
    <>
     
      <div style={{ backgroundColor: "#c4d3f6", paddingBottom: "60px" }}>
          <form className={s.form} onSubmit={handleFormSubmit}
          style={{position: "relative", top: "60px", margin: "0 auto 0"}}>
            {[1].map((i) => (
              <div key={i}  className="table-objects2" style={{display: "flex",
                flexDirection:" column",
                justifyContent: "center",
                alignItems: "center"}}>
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ marginLeft: "20px", display: "flex", justifyContent: "space-between", width: "390px", alignItems: "center" }}>Объект № {i}:<p >{objects2?.[`name${i}`]}</p></div>
                  <textarea
                    className="input-edit"
                    style={{ margin: "20px 10px" }}
                    type="text"
                    value={newValues[`name${i}`] || objects2?.[`name${i}`] || ""}
                    onChange={(e) =>
                      setNewValues((prevValues) => ({
                        ...prevValues,
                        [`name${i}`]: e.target.value,
                      }))
                    }
                    wrap="hard"
                />
                  
                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ margin: "10px 20px 0px 20px" }}>Описание объекта.<br />*Это видно только на главной странице </div>
                  <textarea
  className="input-edit"
  style={{ margin: "20px 10px", padding: "20px 15px" }}
  value={newValues[`info${i}`] || objects2?.[`info${i}`] || ""}
  onChange={(e) =>
    setNewValues({
      ...newValues,
      [`info${i}`]: e.target.value,
    })
  }
  wrap="hard"
/>

                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ margin: "10px 20px" }}>Продажа < br/> *Это будет находится под ценой на объекте</div>
                  <textarea
  className="input-edit"
  style={{ margin: "20px 10px", padding: "20px 15px" }}
  value={newValues[`sale${i}`] || objects2?.[`sale${i}`] || ""}
  onChange={(e) =>
    setNewValues({
      ...newValues,
      [`sale${i}`]: e.target.value,
    })
  }
  wrap="hard"
/>
                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ margin: "0px 20px" }}>Характеристика</div>
                  <textarea
  className="input-edit"
  style={{ margin: "20px 10px", padding: "20px 15px" }}
  value={newValues[`character${i}`] || objects2?.[`character${i}`] || ""}
  onChange={(e) =>
    setNewValues({
      ...newValues,
      [`character${i}`]: e.target.value,
    })
  }
  wrap="hard"
/>
                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ margin: "0px 20px" }}>Цена до скидки, если скидки нет - оставьте пустым</div>
                  <textarea
                    className="input-edit"
                    style={{ margin: "20px 10px" }}
                    value={newValues[`lastprice${i}`] || objects2?.[`lastprice${i}`] || ""}
                    onChange={(e) =>
                      setNewValues((prevValues) => ({
                        ...prevValues,
                        [`lastprice${i}`]: e.target.value,
                      }))
                    }
                    wrap="hard"
                  />
                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ margin: "0px 20px" }}>Цена актуальная:</div>
                  <textarea
                    className="input-edit"
                    style={{ margin: "20px 10px" }}
                    value={newValues[`newprice${i}`] || objects2?.[`newprice${i}`] || ""}
                    onChange={(e) =>
                      setNewValues((prevValues) => ({
                        ...prevValues,
                        [`newprice${i}`]: e.target.value,
                      }))
                    }
                    wrap="hard"
                  />
                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
                  <div style={{ margin: "0px 20px" }}>Акция кончается, дата, если нет - пустое поле:</div>
                  <textarea
                    className="input-edit"
                    style={{ margin: "20px 10px" }}
                    value={newValues[`dateend${i}`] || objects2?.[`dateend${i}`] || ""}
                    onChange={(e) =>
                      setNewValues((prevValues) => ({
                        ...prevValues,
                        [`dateend${i}`]: e.target.value,
                      }))
                    }
                    wrap="hard"
                  />
                </label>
                <hr className="space-admin" />
                <label style={{ margin: "0px 50px" }}>
  <div style={{ margin: "0px 20px" }}>Ссылка на карту только с сайта <a href="https://yandex.ru/map-constructor">https://yandex.ru/map-constructor</a></div>
  <input
    className="input-edit"
    style={{ margin: "20px 10px" }}
    type="text"
    value={newValues.mapLink || objects2?.mapLink || ""}
    onChange={(e) =>
      handleInputChange(e, "mapLink")
    }
  />
</label>
                <hr className="space-admin" />
                  <div className="admin-img">
                    <label style={{marginTop: "10px"}} htmlFor={`file${i}`}>
                      <div className="image-container">
                        <input
                          id={`file${i}`}
                          type="file"
                          onChange
    ={(e) => handleImageUpload(e, i)} 
                        />
                        <div className="image-now" >
                        изображение: <div style={{fontWeight: "500", fontSize: "20px", marginLeft: "10px"}}>  {i}</div>
                        </div>{" "} 
                        {objects2 && objects2[`imageUrl${i}`] && (
                          <div style={{display: "contents", margin: "0 auto 0"}}>
                            <img className="actual-photo" src={objects2[`imageUrl${i}`]} alt="property" /></div>
                        )}
                      </div>
                    </label>
                    <label htmlFor={`file2`}>
              <div className="image-container">
                <input id={`file2`} type="file" onChange={(e) => handleImageUpload(e, 2)} />
                <div className="image-now">
                изображение:{" "}
                  <div style={{ fontWeight: "500", fontSize: "20px", marginLeft: "10px" }}> 2</div>
                </div>{" "}
                {objects2 && objects2[`imageUrl2`] && (
                  <div style={{ display: "contents", margin: "0 auto 0" }}>
                    <img className="actual-photo" src={objects2[`imageUrl2`]} alt="property" />
                  </div>
                )}
              </div>
                    </label>
                    <label htmlFor={`file3`}>
              <div className="image-container">
                <input id={`file3`} type="file" onChange={(e) => handleImageUpload(e, 3)} />
                <div className="image-now">
                изображение:{" "}
                  <div style={{ fontWeight: "500", fontSize: "20px", marginLeft: "10px" }}> 3</div>
                </div>{" "}
                {objects2 && objects2[`imageUrl3`] && (
                  <div style={{ display: "contents", margin: "0 auto 0" }}>
                    <img className="actual-photo" src={objects2[`imageUrl3`]} alt="property" />
                  </div>
                )}
              </div>
                    </label>
                    <label htmlFor={`file4`}>
              <div className="image-container">
                <input id={`file4`} type="file" onChange={(e) => handleImageUpload(e, 4)} />
                <div className="image-now">
                изображение:{" "}
                  <div style={{ fontWeight: "500", fontSize: "20px", marginLeft: "10px" }}> 4</div>
                </div>{" "}
                {objects2 && objects2[`imageUrl4`] && (
                  <div style={{ display: "contents", margin: "0 auto 0" }}>
                    <img className="actual-photo" src={objects2[`imageUrl4`]} alt="property" />
                  </div>
                )}
              </div>
                    </label>
                  </div>
                  <div className="admin-img-2">
                  <label style={{marginTop: "230px"}}>
                  <input
                    type="checkbox"
                    checked={newValues.isVisible}
                    onChange={handleImageCheckboxChange}
                  />
                    {" "}
                    Действует сейчас акция?
          
                  </label>
                  <img style={{position: "relative", top: "30px"}} className={s.discount} src={discount}/>  
                  <label style={{marginTop: "100px"}}>
                  <input
                  type="checkbox"
                  checked={newValues.isSold}
                  onChange={handleSoldCheckboxChange}/>
                  {" "} Продан объект?  
                  </label>
                  <img style={{position: "relative", top: "30px"}} className={s.discount} src={sold}/>
                              
                  <label htmlFor={`file5`}>
                    <div className="file-container">
                      <input id={`file5`} type="file" onChange={(e) => handleFileUpload(e, 5)} />
                      <div className="file-now">
                      
                        <div style={{ fontWeight: "500", fontSize: "20px", marginLeft: "10px", display: "none" }} >
                          {objects2 && objects2[`fileUrl5`] ? objects2[`fileUrl5`].split('/').pop() : "No file selected"}
                        </div>
                      </div>
                      {objects2 && objects2[`fileUrl5`] && (
                        <div style={{ display: "contents", margin: "0 auto 0" }}>
                          <a href={objects2[`fileUrl5`]} rel="noopener noreferrer">
                            Посмотреть какое сейчас стоит pdf на объекте
                          </a>
                        </div>
                      )}
                    </div>
                  </label>
                  </div>   
              </div>
            ))}
            <button type="submit" style={{ margin: "0px  auto 0px", cursor: "pointer", padding: "20px", display: "flex", position:"relative", top: "100px", borderRadius: "10px" }}>Сохранить</button>
          </form>
        </div>
    
    </>
  );
};

export default GeneralEdit2;
