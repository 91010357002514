import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

const LoadingScreen = React.lazy(() => import('./LoadingScreen'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingScreen />
    <App />
    
  </React.StrictMode>
);

reportWebVitals();