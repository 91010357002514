import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from 'firebase/database';
import Header from "../../Main/Header";
import Numbers from "../../manager/Numbers";
import NumbersContact from "../components/numbers";
import button from "../../image/main/pngegg.png"

const Page = () => {
  const [objects, setObjects] = useState({ name1: '' });

  useEffect(() => {
    const db = getDatabase();
    const objectsRef = ref(db, "objects");

    const fetchData = (snapshot, setState) => {
      const data = snapshot.val();
      setState(data);
    };

    onValue(objectsRef, (snapshot) => fetchData(snapshot, setObjects));
  }, []);

  useEffect(() => {
    if (objects.name1) {
      document.title = objects.name1;
    }
  }, [objects.name1]);


  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to keep track of the current image index

  const handlePrevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };


  return (
<div>
  <Header />
  <div className="container-id" style={{top: "80px", position: "relative"}}>
    <div className="container-left">
      <b className="sale">Продажа</b>
          <div className="date-discount">{objects && objects.date1} <br /> </div>

            <div className="lastprice">
              <p>{objects && objects.lastprice1} </p>
              <span>{objects && objects.procent1}</span>
            </div>
            <div className="newprice" style={{fontSize: "30px"}}>{objects && objects.newprice1}   <br /></div>
            <div className="end-discount">{objects && objects.dateend1}</div>
        
        
        <b className="sale-dis" style={{marginTop: "50px"}}>{objects && objects.sale1}</b> 
      <p className="page_char">Описание</p>
      <hr className="page_hr"/>
      <pre
            className="li-styles"
            
          >{objects && objects.character1}</pre>
        </div>
        <div className="container-right">
          <p className="address">{objects && objects.name1}</p>
          <div className="slider-container">
            {Array.from({ length: 3 }, (_, index) => (
              <img
                key={index}
                className="slider-image"
                src={objects[`imageUrl${index + 1}`]}
                alt={`Image ${index + 1}`}
                style={{ display: currentImageIndex === index ? "block" : "none" }}
              />
            ))}
            <div className="slider-buttons">
            <button className="arrow-button prev" onClick={handlePrevSlide}>
              <img className="prev" src={button} alt=""/>
            </button>
            <button className="arrow-button next" onClick={handleNextSlide}>
            <img className="next" src={button} alt=""/>
            </button>
            </div>
          </div>
          <span> Коммерческое предложение в pdf:<a href="./image/" download>Скачать PDF</a></span>
          <div className="sale-product-cart__map">
            <iframe src={objects && objects.mapLink} frameBorder="0" allowFullScreen={true} width="100%" height="300px"></iframe>
          </div>
              <NumbersContact />
            </div>
            
          </div>
        </div>

  );
};

export default Page;

