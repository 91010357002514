import React from 'react';

const FileDownloadLink = ({ fileUrl, fileName }) => {
  const downloadUrl = `${fileUrl}?alt=media&token=ddddc56a-9928-4990-99e8-6d1d446c8525`;

  const handleDownload = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank'; // Open in a new tab
    link.rel = 'noopener noreferrer';

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <button className='downloadCP' onClick={handleDownload}>
      Формате PDF
    </button>
  );
};

export default FileDownloadLink;

